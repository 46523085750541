<script>
	import {filters, spells, available_perks, player_classes} from "../stores.js";

	let selected_filter = "classes_all";

    // Perk name filter.
	function filter_perks() {
		// Reset perks.
		if (!$filters.value) {
			$spells = $spells.map((spell) => ({
				...spell,
				hidden: false,
			}));
			return;
		}
		
		$spells = $spells.map((spell) => ({
			...spell,
			hidden: !spell.spell_name_1.toLowerCase().includes($filters.value.toLowerCase()),
		}));

	}

	// Filter all.
	function filter_all() {
		selected_filter = "all";

		$spells = $spells.map((spell) => ({
			...spell,
			hidden: !$filters.all,
		}));
	}

	// Filter pickable all.
	function filter_pickable_all() {
		selected_filter = "pickable_all";

		if (!$filters.pickable_all) {
			$filters.all = true;
			
			$spells = $spells.map((spell) => ({
				...spell,
				hidden: false,
			}));
			return;
		}

		$filters.all = false;

		$spells = $spells.map((spell) => ({
			...spell,
			hidden: typeof $available_perks[spell.id] === "undefined",
		}));
	}

	// Filter by class.
	function filter_class() {
		if (!$player_classes.includes(selected_filter)) {
				$spells = $spells.map((spell) => ({
				...spell,
				hidden: typeof $available_perks[spell.id] === "undefined",
			}));
		} else {
			$spells = $spells.map((spell) => ({
				...spell,
				hidden: spell.pickable_by[selected_filter] !== true,
			}));
		}
	}

</script>

<input type="text" placeholder="filter by perk name" bind:value={$filters.value} on:input={filter_perks}>

{#await $spells then awaited_spells}
    ({awaited_spells.filter((spell) => !spell.hidden).length} / {awaited_spells.length} visible)
{/await}

<br><br>

<h2>Filters</h2>

<label class="class-filter-item">
	<input type="radio" value="classes_all" bind:group={selected_filter} on:change={filter_class}>
	Show all
</label>

{#each $player_classes as player_class}
	<label class="class-filter-item class-color -{player_class}">
		<input type="radio" value={player_class} bind:group={selected_filter} on:change={filter_class}>
		{player_class}
	</label>
{/each}

<style>
	.class-filter-item {
		margin-right: 10px;
	}
</style>