<script>
	import {enchantments} from "../stores.js";

	// Enchantments store.
	$enchantments = get_enchantments(true).then((awaited_enchantments) => {
		$enchantments = awaited_enchantments;
		console.log("enchantments", $enchantments);
	});

    // Get trigger spells.
	async function get_enchantments(initial = false) {
		if (!initial) {
			if (Object.keys($enchantments).length > 0) {
				return $enchantments;
			}

			await new Promise(resolve => setTimeout(resolve, 100));
			return {};
		}

		const response_enchantments = await fetch("/json/enchantments.json");
		const json_enchantments = await response_enchantments.json();

		return json_enchantments;
	}

</script>

{#await $enchantments}
	<p>Loading data...</p>
{:then awaited_enchantments}
	<div class="enchantments-list">
		{#each awaited_enchantments as enchantment}

            {#if enchantment.charges > 3883}
                <div class="enchantment-item">
                    {enchantment.sRefName}
                </div>
            {/if}

		{/each}
    </div>
{:catch error}
	<p>error: {error}</p>
{/await}

<style>
    .enchantment-item {
        margin-bottom: 6px;
    }
</style>
