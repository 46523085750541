<script>
    import {onMount} from "svelte";
	import {version} from "./stores.js";
	import dayjs from "dayjs";
	import Filters from "./components/Filters.svelte";
	import PerksList from "./components/Perkslist.svelte";
	import EnchantsList from "./components/EnchantsList.svelte";

	const url = new URL(location);
	const search_params = url.searchParams;
	let show_perks = search_params.has("perks");
	let show_enchantments = search_params.has("enchantments");
	let previous_endpoint = "perks";

	if (show_enchantments) {
		previous_endpoint = "enchantments";
	}

	function change_url(endpoint) {
		if (previous_endpoint) {
			search_params.delete(previous_endpoint);
		}
		
		search_params.set(endpoint, "");
		
		history.replaceState(null, null, "?"+search_params.toString());
		//history.pushState(null, null, "?"+search_params.toString());

		if (endpoint === "enchantments") {
			show_perks = false;
			show_enchantments = true;
		} else {
			show_enchantments = false;
			show_perks = true;
		}

		previous_endpoint = endpoint;
	}

	const days_ago = dayjs().diff(dayjs($version), "day");
</script>

<main>
	<h1>Icecrown WoW {previous_endpoint}</h1>

	<p>Data from: {$version} ({days_ago} day{(days_ago > 1) ? "s" : ""} ago)</p>
	<br>

	{#if show_enchantments}
		<button on:click={() => change_url("perks")}>Show Perks</button>
		<br><br>

		<EnchantsList />
	{:else}
		<button on:click={() => change_url("enchantments")}>Show enchantments</button>
		<br><br>

		<Filters />

		<br>

		<PerksList />
	{/if}

</main>

<style>
	:global(.class-color.-warrior) {
		color: #C79C6E;
	}
	:global(.class-color.-paladin) {
		color: #F58CBA;
	}
	:global(.class-color.-hunter) {
		color: #ABD473;
	}
	:global(.class-color.-rogue) {
		color: #FFF569;
	}
	:global(.class-color.-priest) {
		color: #FFFFFF;
	}
	:global(.class-color.-death_knight) {
		color: #C41F3B;
	}
	:global(.class-color.-shaman) {
		color: #0070DE;
	}
	:global(.class-color.-mage) {
		color: #69CCF0;
	}
	:global(.class-color.-warlock) {
		color: #9482C9;
	}
	:global(.class-color.-druid) {
		color: #FF7D0A;
	}
</style>