import {writable, readable} from "svelte/store";

export const version = readable("2022-07-12");

export const filters = writable({
    all: false,
    pickable_all: true,
    value: "",
});

export const spells = writable([]);
export const spell_icons = writable([]);
export const available_perks = writable([]);
export const available_perks_group_ids = writable({});
export const trigger_spells = writable({});
export const spell_durations = writable({});
export const spell_radiuses = writable({});

export const enchantments = writable([]);

export const player_classes = readable([
    "warrior",
    "paladin",
    "hunter",
    "rogue",
    "priest",
    "death_knight",
    "shaman",
    "mage",
    "warlock",
    "druid",
]);
