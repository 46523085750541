<script>
    import {onMount} from "svelte";
	import {get} from "svelte/store";
	import {version, spells, spell_icons, available_perks, available_perks_group_ids, trigger_spells, spell_durations, spell_radiuses} from "../stores.js";
	import Text from "./Text.svelte";

	// Trigger spells store.
	$trigger_spells = get_trigger_spells(true).then((awaited_trigger_spells) => {
		$trigger_spells = awaited_trigger_spells;
		console.log("trigger_spells", $trigger_spells);
	});

	// Spell durations store.
	$spell_durations = get_spell_durations(true).then((awaited_spell_durations) => {
		$spell_durations = awaited_spell_durations;
		console.log("durations", $spell_durations);
	});

	// Spell radiuses store.
	$spell_radiuses = get_spell_radiuses(true).then((awaited_spell_radiuses) => {
		$spell_radiuses = awaited_spell_radiuses;
		console.log("spell_radiuses", $spell_radiuses);
	});

	// Spell icons store.
	$spell_icons = get_spell_icons(true).then((awaited_spell_icons) => {
		$spell_icons = awaited_spell_icons;
		//console.log("spell_icons", $spell_icons);
	});

	// Store functions.
	const functions_store = {
		get_spell_icons,
		get_available_perks,
		get_trigger_spells,
	};

	const variable_store = {
		spell_icons,
		available_perks,
		trigger_spells,
	};

	async function await_data (resolve, reject, awaited_values, function_name, count) {

		awaited_values = await functions_store[function_name]();

		if (Object.keys(awaited_values).length < 1) {
			await_data(resolve, reject, awaited_values, function_name, count);
		} else {
			return resolve();
		}
	}

	$available_perks = get_available_perks(true).then(async (awaited_available_perks) => {
		
		$available_perks = awaited_available_perks;
		console.log("available_perks", $available_perks);

		
		Object.entries(awaited_available_perks).forEach(([key, value]) => {
			const available_perk = value;
			const spell_id = available_perk.spell_id;
			const group_id = available_perk.group_id;

			if (group_id < 0) return;

			if (typeof $available_perks_group_ids[group_id] === "undefined") {
				$available_perks_group_ids[group_id] = [];
			}

			$available_perks_group_ids[group_id].push(spell_id);

		});
		console.log("available_perks_group_ids", $available_perks_group_ids);

		// Get trigger spells.
		await new Promise((resolve, reject) => await_data(resolve, reject, {}, "get_trigger_spells", 0));
		const awaited_trigger_spells = await get_trigger_spells();

		// Get spell icons.
		await new Promise((resolve, reject) => await_data(resolve, reject, {}, "get_spell_icons", 0));
		const awaited_spell_icons = await get_spell_icons();

		// Add icons to trigger spells.
		for (const [key, value] of Object.entries(awaited_trigger_spells)) {
			awaited_trigger_spells[key]["icon"] = get_spell_icon(awaited_spell_icons[value.spell_icon_id]);
		}

		$trigger_spells = awaited_trigger_spells;
	});

	// Spells store.
	$spells = get_spells().then(async (awaited_spells) => {

		// Get spell icons.
		await new Promise((resolve, reject) => await_data(resolve, reject, {}, "get_spell_icons", 0));
		const awaited_spell_icons = await get_spell_icons();

		// Get available perks.
		await new Promise((resolve, reject) => await_data(resolve, reject, {}, "get_available_perks", 0));
		const awaited_available_perks = await get_available_perks();

		// Get trigger spells.
		await new Promise((resolve, reject) => await_data(resolve, reject, {}, "get_trigger_spells", 0));
		const awaited_trigger_spells = await get_trigger_spells();

		//console.log(get(variable_store["spell_icons"]));

		const prepared_spells = awaited_spells.map((spell) => ({
			...spell,
			icon: get_spell_icon(awaited_spell_icons[spell.spell_icon_id]),
			spell_school: get_spell_school(spell.school_mask),
			group_spell_id: get_group_spell_id(awaited_available_perks, spell.id),
			triggered_by: get_triggered_by_spell_id(awaited_spells, awaited_trigger_spells, awaited_spell_icons, spell.id),
			pickable_by: get_pickable_classes(awaited_available_perks, spell.id),
			hidden: typeof awaited_available_perks[spell.id] === "undefined",
		}));

		$spells = prepared_spells;
		console.log("perks", prepared_spells);
	});

	// Class bits.
	const class_bits = {
		warrior: 1,
		paladin: 2,
		hunter: 4,
		rogue: 8,
		priest: 16,
		death_knight: 32,
		shaman: 64,
		mage: 128,
		warlock: 256,
		druid: 1024,
	};

	// Bitwise operation.
	const is_spell_for_class = (number, period) => {
		return !!(number & period);
	};

	// On component mounted.
	async function get_spells() {

		const response_spells = await fetch(`/json/perks-${$version}.json`);
		const json_spells = await response_spells.json();
		//window.perks = spells;

		/*const response_spells_old = await fetch("/json/perks-old1.json");
		const spells_old = await response_spells_old.json();

		let compared_spells = [];
		new_spells.forEach((new_spell) => {
			const old_spell = spells_old.find((old_perk) => old_perk.id === new_spell.id);
			if (typeof old_spell === "undefined") return;

			if (old_spell.spell_name_1 !== new_spell.spell_name_1 || old_spell.spell_description_1 !== new_spell.spell_description_1) {
				compared_spells.push(new_spell)
			}

		});

		console.log("compared_spells", compared_spells);*/

		return json_spells;
	}

	// Get trigger spells.
	async function get_trigger_spells(initial = false) {
		if (!initial) {
			if (Object.keys($trigger_spells).length > 0) {
				return $trigger_spells;
			}

			await new Promise(resolve => setTimeout(resolve, 100));
			return {};
		}

		const response_trigger_spells = await fetch(`/json/trigger-spells-${$version}.json`);
		const json_trigger_spells = await response_trigger_spells.json();

		return json_trigger_spells;
	}

	// Get spell durations.
	async function get_spell_durations() {
		const response_spell_durations = await fetch("/json/spell-durations.json");
		const json_spell_durations = await response_spell_durations.json();
		
		// Normalize spell durations.
		return json_spell_durations.reduce((durations, duration) => {
			durations[duration.id] = duration;
			return durations;
		}, {});
	}

	// Get spell radiuses.
	async function get_spell_radiuses() {
		const response_spell_radiuses = await fetch("/json/spell-radiuses.json");
		let json_spell_radiuses = await response_spell_radiuses.json();

		// Normalize spell radiuses.
		return json_spell_radiuses.reduce((radiuses, radius) => {
			radiuses[radius.id] = radius;
			return radiuses;
		}, {});;
	}

	// Get spell icons.
	async function get_spell_icons(initial = false) {
		if (!initial) {
			if (Object.keys($spell_icons).length > 0) {
				return $spell_icons;
			}

			await new Promise(resolve => setTimeout(resolve, 100));
			return {};
		}

		const response_spell_icons = await fetch("/json/spell-icons.json");
		const json_spell_icons = await response_spell_icons.json();

		// Normalize spell icons.
		return json_spell_icons.reduce((icons, icon) => {
			icons[icon.id] = icon.file_name;
			return icons;
		}, {});
	}

	// Get available perks.
	async function get_available_perks(initial = false) {
		if (!initial) {
			if (Object.keys($available_perks).length > 0) {
				return $available_perks;
			}

			await new Promise(resolve => setTimeout(resolve, 100));
			return {};
		}

		const response_available_perks = await fetch(`/json/perks-availability-${$version}.json`);
		const json_available_perks = await response_available_perks.json();

		// Normalize available perks.
		return json_available_perks.reduce((perks, perk) => {
			perks[perk.spell_id] = perk;
			return perks;
		}, {});
	}

	// Get spell icon.
	function get_spell_icon(spell_file_name) {
		if (typeof spell_file_name === "undefined") {
			console.log("UNDEFINED");
			return "";
		} else if (typeof spell_file_name !== "string") {
			console.log(spell_file_name, typeof spell_file_name);
		}

		spell_file_name = spell_file_name.toLowerCase();
		spell_file_name = spell_file_name.replace("interface\\icons\\", "");
		spell_file_name = spell_file_name.replace("ability_temp", "temp");
		spell_file_name = spell_file_name.replace(".", "");

		return `https://wotlkdb.com/static/images/wow/icons/large/${spell_file_name}.jpg`;
	}

	// Get spell school.
	// TODO: use bitwise
	function get_spell_school(spell_school_mask) {
		const spell_schools = {
			0: "none",
			1: "Physical",
			2: "Holy",
			4: "Fire",
			8: "Nature",
			16: "Frost",
			32: "Shadow",
			64: "Arcane",
		};

		return spell_schools[spell_school_mask];
	}

	// Get group spell id.
	function get_group_spell_id(available_perks, spell_id) {
		const availability = available_perks[spell_id];

		if (typeof availability === "undefined") return;

		const group_id = availability.group_id;

		return group_id;
	}

	// Get triggered by spell id.
	function get_triggered_by_spell_id(spells, trigger_spells, spell_icons, spell_id) {
		const trigger_spell = trigger_spells[spell_id];

		if (typeof trigger_spell === "undefined") return;

		const found_spell = spells.find((spell) => spell.effect_trigger_spell_1 === trigger_spell.id || spell.effect_trigger_spell_2 === trigger_spell.id || spell.effect_trigger_spell_3 === trigger_spell.id);

		const spell_icon = spell_icons[found_spell.spell_icon_id];

		found_spell.icon = get_spell_icon(spell_icon);

		return found_spell;
	}

	// Get by which class is perk pickable.
	function get_pickable_classes(available_perks, spell_id) {
		const available_perk = available_perks[spell_id];

		const pickable_classes = {};
		if (typeof available_perk === "undefined") {
			Object.keys(class_bits).forEach((key) => {
				pickable_classes[key] = null;
			});
			
			return pickable_classes;
		}
		
		Object.entries(class_bits).forEach(([key, value]) => {
			const class_name = key;
			const bit = value;

			pickable_classes[class_name] = is_spell_for_class(available_perk.allowable_class_id, bit);
		});

		return pickable_classes;
	}
	
	// Get grouped spells.
	function get_grouped_spells(spell_ids, current_spell_id) {
		let string = "";
		
		spell_ids.forEach((spell_id) => {
			if (spell_id === current_spell_id) return;
			const spell = $spells.find((perk) => perk.id === spell_id);
			string += `<li>${spell.spell_name_1} [${spell_id}]</li>`;
		});

		return string;
	}

	const max_stacks = 10;
</script>

{#await $spells}
	<p>Loading data...</p>
{:then awaited_spells}
	<div class="perks-list">
		{#each awaited_spells as spell}

			{#if !spell.hidden}
			
				<div class="perk-item" id={spell.id}>
					
					<div class="perk-info">
						<div class="img-wrap">
							<img src="{spell.icon}" class="perk-item-img" alt="" loading="lazy">
						</div>
						
						<div class="perk-name -more-info">
							<div class="perk-column">
								<h2>{spell.spell_name_1} [{spell.id}]</h2>


								<ul>
									{#if spell.proc_chance > 100}
										<li>Permanent aura</li>
									{:else}
										<li>Proc chance: <strong>{spell.proc_chance}%</strong></li>
									{/if}

									{#if spell.spell_school !== "none"}
										<li>Magic school: <strong>{spell.spell_school}</strong></li>
									{/if}

									<!--{#if spell.effect_bonus_multiplier_1}
										<li>Effect 1 multiplier: <strong>{spell.effect_bonus_multiplier_1}</strong></li>
									{/if}
									
									{#if spell.effect_bonus_multiplier_2}
										<li>Effect 2 multiplier: <strong>{spell.effect_bonus_multiplier_2}</strong></li>
									{/if}
									
									{#if spell.effect_bonus_multiplier_3}
										<li>Effect 3 multiplier: <strong>{spell.effect_bonus_multiplier_3}</strong></li>
									{/if}-->

									<!--<li>
										Is stackable: 
										<strong>
											{#if spell.stack_amount > 0}
												{spell.stack_amount}x
											{:else}
												false
											{/if}
										</strong>
									</li>-->

									<!--<li>
										Is a pickable perk: 
										<strong>{(typeof $available_perks[spell.id] !== "undefined").toString()}</strong>
									</li>-->

									<!--<li>
										Is unique: 
										<strong>
											{#if typeof $available_perks[spell.id] === "undefined"}
												unknown
											{:else}
												{(typeof $available_perks[spell.id] !== "undefined" && $available_perks[spell.id].is_unique).toString()}
											{/if}
										</strong>
									</li>-->

									{#if typeof $available_perks[spell.id] !== "undefined"}
										<li>
											{#if $available_perks[spell.id].is_unique}
												Is not stackable
											{:else}
												Is stackable ({max_stacks}x max)
											{/if}
										</li>
									{/if}

									{#if spell.group_spell_id >= 0}
										<li>
											Is grouped with:
											<ul>
												{@html get_grouped_spells($available_perks_group_ids[spell.group_spell_id], spell.id)}
											</ul>
										</li>
									{/if}
								</ul>

							</div>

							<div class="perk-column -right">
								<h3>Pickable by:</h3>

								{#each Object.keys(class_bits) as class_name}
									{#if (typeof spell.pickable_by !== "undefined" && spell.pickable_by[class_name])}
										<strong class="class-color -{class_name}">{class_name}</strong>
										<br>
									{/if}
								{/each}
							</div>
						</div>
					</div>

					<div class="perk-texts">
						<div class="perk-text -description">
							<h3>Description:</h3>
							<p><Text {spell} type="description"/></p>
						</div>
						
						<div class="perk-text -tooltip">
							<h3>Tooltip:</h3>

							<p><Text {spell} type="tooltip"/></p>
						</div>
					</div>

					{#if spell.effect_trigger_spell_1 || spell.effect_trigger_spell_2 || spell.effect_trigger_spell_3}
						<div class="perk-triggers">
							<h3>Triggers:</h3>

							{#if spell.effect_trigger_spell_1}
								<div class="perk-info">
									<div class="img-wrap">
										<img src={$trigger_spells[spell.effect_trigger_spell_1].icon} class="perk-item-img" alt="">
									</div>
									
									<div class="perk-name">
										{$trigger_spells[spell.effect_trigger_spell_1].spell_name_1} [<a href="#{$trigger_spells[spell.effect_trigger_spell_1].id}">{$trigger_spells[spell.effect_trigger_spell_1].id}</a>] (Effect 1)
									</div>
								</div>
							{/if}
							
							{#if spell.effect_trigger_spell_2}
								<div class="perk-info">
									<div class="img-wrap">
										<img src={$trigger_spells[spell.effect_trigger_spell_2].icon} class="perk-item-img" alt="">
									</div>
									
									<div class="perk-name">
										{$trigger_spells[spell.effect_trigger_spell_2].spell_name_1} [<a href="#{$trigger_spells[spell.effect_trigger_spell_2].id}">{$trigger_spells[spell.effect_trigger_spell_2].id}</a>] (Effect 2)
									</div>
								</div>
							{/if}
							
							{#if spell.effect_trigger_spell_3}
								<div class="perk-info">
									<div class="img-wrap">
										<img src={$trigger_spells[spell.effect_trigger_spell_3].icon} class="perk-item-img" alt="">
									</div>
									
									<div class="perk-name">
										{$trigger_spells[spell.effect_trigger_spell_3].spell_name_1} [<a href="#{$trigger_spells[spell.effect_trigger_spell_3].id}">{$trigger_spells[spell.effect_trigger_spell_3].id}</a>] (Effect 3)
									</div>
								</div>
							{/if}
						</div>
					{/if}

					{#if spell.triggered_by}
						<div class="perk-triggers">
							<h3>Triggered by:</h3>

							<div class="perk-info">
								<div class="img-wrap">
									<img src={spell.triggered_by.icon} class="perk-item-img" alt="">
								</div>
								
								<div class="perk-name">
									{spell.triggered_by.spell_name_1} [<a href="#{spell.triggered_by.id}">{spell.triggered_by.id}</a>]
								</div>
							</div>
						</div>
					{/if}

				</div>
				
			{/if}

		{/each}
	</div>
{:catch error}
	<p>error: {error}</p>
{/await}

<style>
	.perks-list {
		width: 1000px;
		max-width: 75vw;
		display: grid;
		justify-content: center;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.perk-item {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid white;
		padding-top: 20px;
		margin-top: 20px;
	}

	.perk-info {
		display: flex;
		flex-basis: 100%;
		margin-bottom: 25px;
	}

	.perk-info .img-wrap {
		flex-basis: 80px;
	}
	.perk-info .img-wrap img {
		display: block;
	}

	.perk-info .perk-name {
		flex-basis: calc(100% - 80px);
		text-align: left;
	}
	.perk-info .perk-name.-more-info {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 25px;
	}
	.perk-info .perk-name.-more-info .-right {
		text-align: right;
	}

	.perk-triggers {
		flex-basis: 100%;
		text-align: right;
	}
	.perk-triggers .perk-info {
		text-align: right;
	}
	.perk-triggers .perk-info .img-wrap {
		order: 2;
	}
	.perk-triggers .perk-info .img-wrap img {
		margin-left: auto;
	}
	.perk-triggers .perk-info .perk-name {
		text-align: right;
	}

	.perk-texts {
		flex-basis: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 30px;
		position: relative;
		margin-bottom: 15px;
	}
	.perk-texts::before {
		content: "";
		width: 1px;
		height: 100%;
		background-color: white;
		position: absolute;
		top: 0;
		left: 50%;
	}

	/*.perk-text {
		
	}*/
	.perk-text.-description {
		text-align: left;
	}
	.perk-text.-tooltip {
		text-align: right;
	}

</style>
