<script>
	import {trigger_spells, spell_durations, spell_radiuses} from "../stores.js";
    export let spell;
    export let type;

    let text;

    if (type === "tooltip") {
        text = spell.spell_tool_tip1;
    } else {
        text = spell.spell_description_1;
    }

    // Effect 1.
    const effect_base_points_1 = parseInt(spell.effect_base_points_1);
    const effect_die_sides1 = parseInt(spell.effect_die_sides_1);
    const effect_1 = effect_base_points_1 + effect_die_sides1;

    text = text.replace("$s1", Math.abs(effect_1));

    // Effect 2.
    const effect_base_points_2 = parseInt(spell.effect_base_points_2);
    const effect_die_sides2 = parseInt(spell.effect_die_sides_2);
    const effect_2 = effect_base_points_2 + effect_die_sides2;

    text = text.replace("$s2", Math.abs(effect_2));

    // Effect 3.
    const effect_base_points_3 = parseInt(spell.effect_base_points_3);
    const effect_die_sides3 = parseInt(spell.effect_die_sides_3);
    const effect_3 = effect_base_points_3 + effect_die_sides3;

    text = text.replace("$s3", Math.abs(effect_3));

    // Duration;
    const duration_id = spell.duration_index;
    const duration = $spell_durations[duration_id];
    if (typeof duration !== "undefined") {
        //console.log("hej", duration);
        const duration_milliseconds = duration.duration;
        const duration_seconds = duration_milliseconds / 1000;

        text = text.replace("$d", `${duration_seconds} seconds`);
    }

    // Period durations 1.
    const period_duration_milliseconds_1 = spell.effect_amplitude_1;
    if (period_duration_milliseconds_1) {
        const period_duration_seconds = period_duration_milliseconds_1 / 1000;

        text = text.replace("$t1", `${period_duration_seconds}`);
    }

    // Period durations 2.
    const period_duration_milliseconds_2 = spell.effect_amplitude_2;
    if (period_duration_milliseconds_2) {
        const period_duration_seconds = period_duration_milliseconds_2 / 1000;

        text = text.replace("$t2", `${period_duration_seconds}`);
    }

    // Period durations 3.
    const period_duration_milliseconds_3 = spell.effect_amplitude_3;
    if (period_duration_milliseconds_3) {
        const period_duration_seconds = period_duration_milliseconds_3 / 1000;

        text = text.replace("$t3", `${period_duration_seconds}`);
    }

    // Spell radius effect 1.
    const effect_radius_1_id = spell.effect_radius_index_1;
    if (effect_radius_1_id) {
        const effect_radius = $spell_radiuses[effect_radius_1_id];

        if (effect_radius) {
            text = text.replace("$a1", `${effect_radius.radius}`);
        }
    }

    // Spell radius effect 2.
    const effect_radius_2_id = spell.effect_radius_index_2;
    if (effect_radius_2_id) {
        const effect_radius = $spell_radiuses[effect_radius_2_id];

        if (effect_radius) {
            text = text.replace("$a2", `${effect_radius.radius}`);
        }
    }

    // Spell radius effect 3.
    const effect_radius_3_id = spell.effect_radius_index_3;
    if (effect_radius_3_id) {
        const effect_radius = $spell_radiuses[effect_radius_3_id];

        if (effect_radius) {
            text = text.replace("$a3", `${effect_radius.radius}`);
        }
    }

    // Spell chain effect 1.
    const effect_chain_1 = spell.effect_chain_target_1;
    if (effect_chain_1) {
        text = text.replace("$x1", `${effect_chain_1}`);
    }

    // Spell chain effect 2.
    const effect_chain_2 = spell.effect_chain_target_2;
    if (effect_chain_2) {
        text = text.replace("$x2", `${effect_chain_2}`);
    }

    // Spell chain effect 3.
    const effect_chain_3 = spell.effect_chain_target_3;
    if (effect_chain_3) {
        text = text.replace("$x3", `${effect_chain_3}`);
    }

    // Different spell duration;
    const different_spell_duration_regex_string = /\$[-0-9.,]+[-0-9.,d]*\b/gm;
    const different_spell_duration_regex = different_spell_duration_regex_string.exec(text);
    if (different_spell_duration_regex !== null) {
        const different_spell_duration_regex_length = different_spell_duration_regex[0].length;
        const different_spell_duration_regex_id = parseInt(different_spell_duration_regex[0].substring(1, different_spell_duration_regex_length - 1));
        const different_spell_duration_spell = trigger_spells[different_spell_duration_regex_id];
        if (different_spell_duration_spell) {
            const different_spell_duration_id = different_spell_duration_spell.duration_index;
            const duration = $spell_durations[different_spell_duration_id];
            if (typeof duration !== "undefined") {
                const duration_milliseconds = duration.duration;
                const duration_seconds = duration_milliseconds / 1000;

                text = text.replace(different_spell_duration_regex[0], `${duration_seconds} seconds`);
            }
        }
    }

    // Different spell effect 1.
    const different_spell_effect_1_regex_string = /\$[-0-9.,]+[-0-9.,s1]*\b/gm;
    const different_spell_effect_1_regex = different_spell_effect_1_regex_string.exec(text)
    if (different_spell_effect_1_regex !== null) {
        const different_spell_effect_1_regex_length = different_spell_effect_1_regex[0].length;
        const different_spell_effect_id_1 = parseInt(different_spell_effect_1_regex[0].substring(1, different_spell_effect_1_regex_length - 2));
        const different_spell_1 = trigger_spells[different_spell_effect_id_1];
        //console.log(text, different_spell_effect_1_regex[0], different_spell_effect_id_1, different_spell_1);
        if (different_spell_1) {
            const different_spell_effect_1_base_points = parseInt(different_spell_1.effect_base_points_1);
            const different_spell_effect_1_sides = parseInt(different_spell_1.effect_die_sides_1);

            let different_spell_effect_1;
            if (different_spell_effect_1_base_points > 0) {
                different_spell_effect_1 = different_spell_effect_1_base_points + different_spell_effect_1_sides;
            } else {
                different_spell_effect_1 = Math.abs(different_spell_effect_1_base_points) - different_spell_effect_1_sides;
            }

            text = text.replace(different_spell_effect_1_regex[0], Math.abs(different_spell_effect_1));
        }
    }
    
</script>

{text}
